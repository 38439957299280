const BrowserFS = require("browserfs");

/*
 * EmscriptenRunner
 */
export default function EmscriptenRunner(canvas, game_data) {
  this._hooks = { start: [], reset: [] };
  this.SDL_PauseAudio = undefined;
  // This is somewhat wrong, because our Emscripten-based emulators
  // are currently compiled to start immediately when their js file
  // is loaded.
  window.Module = {
    arguments: game_data.emulator_arguments,
    screenIsReadOnly: true,
    print: function (text) {
      console.log(text);
    },
    printErr: function (text) {
      console.log(text);
    },
    canvas: canvas,
    noInitialRun: false,
    locateFile: game_data.locateAdditionalJS,
    wasmBinary: game_data.wasmBinary,
    preInit: () => {
      // Re-initialize BFS to just use the writable in-memory storage.
      BrowserFS.initialize(game_data.fs);
      const BFS = new BrowserFS.EmscriptenFS();
      // Mount the file system into Emscripten.
      /* eslint-disable no-undef */
      FS.mkdir("/emulator");
      FS.mount(BFS, { root: "/" }, "/emulator");
      /* eslint-enable no-undef */
    },
    preRun: [
      () => {
        this._hooks.start.forEach((f) => {
          //try {
          f && f();
          //} catch(x) {
          //  console.warn(x);
          //}
        });
      },
    ],
  };
}

EmscriptenRunner.prototype.start = () => {};

EmscriptenRunner.prototype.pause = () => {};

EmscriptenRunner.prototype.stop = () => {};

EmscriptenRunner.prototype.mute = function () {
  try {
    if (!this.SDL_PauseAudio)
      this.SDL_PauseAudio = window.Module.cwrap("this.SDL_PauseAudio", "", [
        "number",
      ]);
    this.SDL_PauseAudio(true);
  } catch (x) {
    console.log("Unable to change audio state:", x);
  }
};

EmscriptenRunner.prototype.unmute = function () {
  try {
    if (!this.SDL_PauseAudio)
      this.SDL_PauseAudio = window.Module.cwrap("this.SDL_PauseAudio", "", [
        "number",
      ]);
    this.SDL_PauseAudio(false);
  } catch (x) {
    console.log("Unable to change audio state:", x);
  }
};

EmscriptenRunner.prototype.onStarted = function (func) {
  this._hooks.start.push(func);
};

EmscriptenRunner.prototype.onReset = function (func) {
  this._hooks.reset.push(func);
};

EmscriptenRunner.prototype.requestFullScreen = () => {};

const BrowserFS = require("browserfs");

import EmscriptenRunner from "./EmscriptenRunner";

export default function Emulator(canvas, callbacks, loadFiles) {
  if (typeof callbacks !== "object") {
    callbacks = { before_emulator: null, before_run: callbacks };
  }
  let has_started = false;

  const splash = {
    loading_text: "",
    spinning: true,
    finished_loading: false,
    colors: defaultSplashColors,
    table: null,
    splashimg: new Image(),
  };

  let runner;

  let muted = false;
  let SDL_PauseAudio;

  this.isMuted = function () {
    return muted;
  };
  this.mute = function () {
    return this.setMute(true);
  };
  this.unmute = function () {
    return this.setMute(false);
  };
  this.toggleMute = function () {
    return this.setMute(!muted);
  };
  this.setMute = function (state) {
    muted = state;
    if (runner) {
      if (state) {
        runner.mute();
      } else {
        runner.unmute();
      }
    } else {
      try {
        if (!SDL_PauseAudio)
          SDL_PauseAudio = window.Module.cwrap("SDL_PauseAudio", "", [
            "number",
          ]);
        SDL_PauseAudio(state);
      } catch (x) {
        console.log("Unable to change audio state:", x);
      }
    }
    return this;
  };

  setupGamepad()

  let css_resolution, scale, aspectRatio;
  // right off the bat we set the canvas's inner dimensions to
  // whatever it's current css dimensions are; this isn't likely to be
  // the same size that dosbox/jsmame will set it to, but it avoids
  // the case where the size was left at the default 300x150
  if (!canvas.hasAttribute("width")) {
    const style = getComputedStyle(canvas);
    canvas.width = parseInt(style.width, 10);
    canvas.height = parseInt(style.height, 10);
  }

  this.setScale = function (_scale) {
    scale = _scale;
    return this;
  };

  this.setSplashImage = function (_splashimg) {
    if (_splashimg) {
      if (_splashimg instanceof Image) {
        if (splash.splashimg.parentNode) {
          splash.splashimg.src = _splashimg.src;
        } else {
          splash.splashimg = _splashimg;
        }
      } else {
        splash.splashimg.src = _splashimg;
      }
    }
    return this;
  };

  this.setCSSResolution = function (_resolution) {
    css_resolution = _resolution;
    return this;
  };

  this.setAspectRatio = function (_aspectRatio) {
    aspectRatio = _aspectRatio;
    return this;
  };

  this.setCallbacks = function (_callbacks) {
    if (typeof _callbacks !== "object") {
      callbacks = { before_emulator: null, before_run: _callbacks };
    } else {
      callbacks = _callbacks;
    }
    return this;
  };

  this.setSplashColors = function (colors) {
    splash.colors = colors;
    return this;
  };

  this.setLoad = function (loadFunc) {
    loadFiles = loadFunc;
    return this;
  };

  this.start = function (options) {
    if (has_started) return false;
    has_started = true;
    const defaultOptions = { waitAfterDownloading: false, hasCustomCSS: false };
    if (typeof options !== "object") {
      options = defaultOptions;
    } else {
      options.__proto__ = defaultOptions;
    }

    let k, c, game_data;
    setupSplash(canvas, splash, options);
    drawsplash();

    let loading;

    if (typeof loadFiles === "function") {
      loading = loadFiles(fetch_file, splash);
    } else {
      loading = Promise.resolve(loadFiles);
    }
    loading
      .then(function (_game_data) {
        return new Promise(function (resolve, reject) {
          const inMemoryFS = new BrowserFS.FileSystem.InMemory();
          // If the browser supports IndexedDB storage, mirror writes to that storage
          // for persistence purposes.
          if (BrowserFS.FileSystem.IndexedDB.isAvailable()) {
            const AsyncMirrorFS = BrowserFS.FileSystem.AsyncMirror,
              IndexedDB = BrowserFS.FileSystem.IndexedDB;
            var deltaFS = new AsyncMirrorFS(
              inMemoryFS,
              new IndexedDB(
                // eslint-disable-next-line no-unused-vars
                function (e, fs) {
                  if (e) {
                    // we probably weren't given access;
                    // private window for example.
                    // don't fail completely, just don't
                    // use indexeddb
                    deltaFS = inMemoryFS;
                    finish();
                  } else {
                    // Initialize deltaFS by copying files from async storage to sync storage.
                    deltaFS.initialize(function (e) {
                      if (e) {
                        reject(e);
                      } else {
                        finish();
                      }
                    });
                  }
                },
                "fileSystemKey" in _game_data
                  ? _game_data.fileSystemKey
                  : "emularity"
              )
            );
          } else {
            finish();
          }

          function finish() {
            game_data = _game_data;

            // Any file system writes to MountableFileSystem will be written to the
            // deltaFS, letting us mount read-only zip files into the MountableFileSystem
            // while being able to "write" to them.
            game_data.fs = new BrowserFS.FileSystem.OverlayFS(
              deltaFS,
              new BrowserFS.FileSystem.MountableFileSystem()
            );
            game_data.fs.initialize(function (e) {
              if (e) {
                console.error("Failed to initialize the OverlayFS:", e);
                reject();
              } else {
                const Buffer = BrowserFS.BFSRequire("buffer").Buffer;
                const fetch = (file) => {
                  if (
                    "data" in file &&
                    file.data !== null &&
                    typeof file.data !== "undefined"
                  ) {
                    return Promise.resolve(file.data);
                  }
                  return fetch_file(
                    file.title,
                    file.url,
                    "arraybuffer",
                    file.optional
                  );
                };

                const mountat = (drive) => (data) => {
                  if (data !== null) {
                    drive = drive.toLowerCase();
                    const mountpoint = "/" + drive;
                    // Mount into RO MFS.
                    game_data.fs
                      .getOverlayedFileSystems()
                      .readable.mount(mountpoint, BFSOpenZip(new Buffer(data)));
                  }
                };

                const saveat = (filename) => (data) => {
                  if (data !== null) {
                    if (filename.includes("/")) {
                      const parts = filename.split("/");
                      for (let i = 1; i < parts.length; i++) {
                        const path = "/" + parts.slice(0, i).join("/");
                        if (!game_data.fs.existsSync(path)) {
                          game_data.fs.mkdirSync(path);
                        }
                      }
                    }
                    game_data.fs.writeFileSync(
                      "/" + filename,
                      new Buffer(data),
                      null,
                      flag_w,
                      0x1a4
                    );
                  }
                };

                const promises = game_data.files.map((f) => {
                  if (f && f.file) {
                    if (f.drive) {
                      return fetch(f.file).then(mountat(f.drive));
                    } else if (f.mountpoint) {
                      return fetch(f.file).then(saveat(f.mountpoint));
                    }
                  }
                  return null;
                });
                // this is kinda wrong; it really only applies when we're loading something created by Emscripten
                if (
                  "emulatorWASM" in game_data &&
                  game_data.emulatorWASM &&
                  "WebAssembly" in window
                ) {
                  promises.push(
                    fetch({
                      title: "WASM Binary",
                      url: game_data.emulatorWASM,
                    }).then(function (data) {
                      game_data.wasmBinary = data;
                    })
                  );
                }
                Promise.all(promises).then(resolve, reject);
              }
            });
          }
        });
      })
      .then(
        function () {
          if (!game_data || splash.failed_loading) {
            return null;
          }
          if (options.waitAfterDownloading) {
            return new Promise(function (resolve) {
              splash.setTitle("Press any key to continue...");
              splash.spinning = false;

              // stashes these event listeners so that we can remove them after
              window.addEventListener("keypress", (k = keyevent(resolve)));
              canvas.addEventListener("click", (c = resolve));
              splash.splashElt.addEventListener("click", c);
            });
          }
          return Promise.resolve();
        },
        function () {
          if (splash.failed_loading) {
            return;
          }
          splash.setTitle("Failed to download game data!");
          splash.failed_loading = true;
        }
      )
      .then(
        function () {
          if (!game_data || splash.failed_loading) {
            return null;
          }
          splash.spinning = true;
          window.removeEventListener("keypress", k);
          canvas.removeEventListener("click", c);
          splash.splashElt.removeEventListener("click", c);

          // Don't let arrow, pg up/down, home, end affect page position
          blockSomeKeys();
          setupFullScreen();
          disableRightClickContextMenu(canvas);

          // Emscripten doesn't use the proper prefixed functions for fullscreen requests,
          // so let's map the prefixed versions to the correct function.
          canvas.requestPointerLock = getpointerlockenabler();

          moveConfigToRoot(game_data.fs);

          if (callbacks && callbacks.before_emulator) {
            try {
              callbacks.before_emulator();
            } catch (x) {
              console.log(x);
            }
          }

          if ("runner" in game_data) {
            if (
              game_data.runner == EmscriptenRunner ||
              game_data.runner.prototype instanceof EmscriptenRunner
            ) {
              // this is a stupid hack. Emscripten-based
              // apps currently need the runner to be set
              // up first, then we can attach the
              // script. The others have to do it the
              // other way around.
              runner = setup_runner();
            }
          }

          if (game_data.emulatorJS) {
            splash.setTitle("Launching Emulator");
            return attach_script(game_data.emulatorJS);
          } else {
            splash.setTitle("Non-system disk or disk error");
          }
          return null;
        },
        function () {
          if (!game_data || splash.failed_loading) {
            return null;
          }
          splash.setTitle("Invalid media, track 0 bad or unusable");
          splash.failed_loading = true;
        }
      )
      .then(function () {
        if (!game_data || splash.failed_loading) {
          return null;
        }
        if ("runner" in game_data) {
          if (!runner) {
            runner = setup_runner();
          }
          runner.start();
        }
      });

    function setup_runner() {
      const runner = new game_data.runner(canvas, game_data);
      resizeCanvas(
        canvas,
        1,
        game_data.nativeResolution,
        game_data.aspectRatio
      );
      runner.onStarted(function () {
        splash.finished_loading = true;
        splash.hide();
        if (callbacks && callbacks.before_run) {
          setTimeout(function () {
            callbacks.before_run();
          }, 0);
        }
      });
      runner.onReset(function () {
        if (muted) {
          runner.mute();
        }
      });
      return runner;
    }

    return this;
  };

  const fetch_file = function (title, url, rt, optional) {
    const needsCSS = splash.table.dataset.hasCustomCSS == "false";
    const row = addRow(splash.table);
    const titleCell = row[0];
    const statusCell = row[1];
    titleCell.textContent = title;
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = rt || "arraybuffer";
      xhr.onprogress = function (e) {
        titleCell.innerHTML =
          title +
          ' <span style="font-size: smaller">' +
          formatSize(e) +
          "</span>";
      };
      xhr.onload = function () {
        if (xhr.status === 200) {
          success();
          resolve(xhr.response);
        } else if (optional) {
          success();
          resolve(null);
        } else {
          failure();
          reject();
        }
      };
      xhr.onerror = function () {
        if (optional) {
          success();
          resolve(null);
        } else {
          failure();
          reject();
        }
      };
      function success() {
        statusCell.textContent = "✔";
        titleCell.parentNode.classList.add("emularity-download-success");
        titleCell.textContent = title;
        if (needsCSS) {
          titleCell.style.fontWeight = "bold";
          titleCell.parentNode.style.backgroundColor =
            splash.getColor("foreground");
          titleCell.parentNode.style.color = splash.getColor("background");
        }
      }
      function failure() {
        statusCell.textContent = "✘";
        titleCell.parentNode.classList.add("emularity-download-failure");
        titleCell.textContent = title;
        if (needsCSS) {
          titleCell.style.fontWeight = "bold";
          titleCell.parentNode.style.backgroundColor =
            splash.getColor("failure");
          titleCell.parentNode.style.color = splash.getColor("background");
        }
      }
      xhr.send();
    });
  };

  splash.setTitle = function (title) {
    splash.titleElt.textContent = title;
  };

  splash.hide = function () {
    splash.splashElt.style.display = "none";
  };

  splash.getColor = function (name) {
    return name in splash.colors
      ? splash.colors[name]
      : defaultSplashColors[name];
  };

  const drawsplash = function () {
    canvas.setAttribute("moz-opaque", "");
    if (!splash.splashimg.src) {
      splash.splashimg.src = "logo/emularity_color_small.png";
    }
  };

  function getpointerlockenabler() {
    return (
      canvas.requestPointerLock ||
      canvas.mozRequestPointerLock ||
      canvas.webkitRequestPointerLock
    );
  }

  this.isfullscreensupported = function () {
    return (
      document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled
    );
  };

  function setupFullScreen() {
    const fullScreenChangeHandler = function () {
      if (!(document.mozFullScreenElement || document.fullScreenElement)) {
        resizeCanvas(canvas, scale, css_resolution, aspectRatio);
      }
    };
    if ("onfullscreenchange" in document) {
      document.addEventListener("fullscreenchange", fullScreenChangeHandler);
    } else if ("onmozfullscreenchange" in document) {
      document.addEventListener("mozfullscreenchange", fullScreenChangeHandler);
    } else if ("onwebkitfullscreenchange" in document) {
      document.addEventListener(
        "webkitfullscreenchange",
        fullScreenChangeHandler
      );
    }
  }

  this.requestFullScreen = function () {
    if (
      typeof window.Module == "object" &&
      "requestFullScreen" in window.Module
    ) {
      window.Module.requestFullScreen(1, 0);
    } else if (runner) {
      runner.requestFullScreen();
    }
  };
}

// Emulator Def end!


const formatSize = function (event) {
  if (event.lengthComputable)
    return (
      "(" +
      (event.total
        ? ((event.loaded / event.total) * 100).toFixed(0)
        : "100") +
      "%; " +
      formatBytes(event.loaded) +
      " of " +
      formatBytes(event.total) +
      ")"
    );
  return "(" + formatBytes(event.loaded) + ")";
};

const setupGamepad = () => {
  // This is the bare minimum that will allow gamepads to work. If
  // we don't listen for them then the browser won't tell us about
  // them.
  // TODO: add hooks so that some kind of UI can be displayed.
  window.addEventListener("gamepadconnected", (e) => {
    console.log(
      "Gamepad connected at index %d: %s. %d buttons, %d axes.",
      e.gamepad.index,
      e.gamepad.id,
      e.gamepad.buttons.length,
      e.gamepad.axes.length
    );
  });

  window.addEventListener("gamepaddisconnected", (e) => {
    console.log(
      "Gamepad disconnected from index %d: %s",
      e.gamepad.index,
      e.gamepad.id
    );
  });
};

const defaultSplashColors = {
  foreground: "white",
  background: "black",
  failure: "red",
};

const attach_script = (js_url) =>
  new Promise(function (resolve, reject) {
    let newScript;
    function loaded(e) {
      if (e.target == newScript) {
        newScript.removeEventListener("load", loaded);
        newScript.removeEventListener("error", failed);
        resolve();
      }
    }
    function failed(e) {
      if (e.target == newScript) {
        newScript.removeEventListener("load", loaded);
        newScript.removeEventListener("error", failed);
        reject();
      }
    }
    if (js_url) {
      const head = document.getElementsByTagName("head")[0];
      newScript = document.createElement("script");
      newScript.addEventListener("load", loaded);
      newScript.addEventListener("error", failed);
      newScript.type = "text/javascript";
      newScript.src = js_url;
      head.appendChild(newScript);
    }
  });

const addRow = (table) => {
  const needsCSS = table.dataset.hasCustomCSS == "false";
  const row = table.insertRow(-1);
  if (needsCSS) {
    row.style.textAlign = "center";
  }
  const cell = row.insertCell(-1);
  if (needsCSS) {
    cell.style.position = "relative";
  }
  const titleCell = document.createElement("span");
  titleCell.classList.add("emularity-download-title");
  titleCell.textContent = "—";
  if (needsCSS) {
    titleCell.style.verticalAlign = "center";
    titleCell.style.minHeight = "24px";
    titleCell.style.whiteSpace = "nowrap";
  }
  cell.appendChild(titleCell);
  const statusCell = document.createElement("span");
  statusCell.classList.add("emularity-download-status");
  if (needsCSS) {
    statusCell.style.position = "absolute";
    statusCell.style.left = "0";
    statusCell.style.paddingLeft = "0.5em";
  }
  cell.appendChild(statusCell);
  return [titleCell, statusCell];
};

const setupSplash = (canvas, splash, globalOptions) => {
  splash.splashElt = document.getElementById("emularity-splash-screen");
  if (!splash.splashElt) {
    splash.splashElt = document.createElement("div");
    splash.splashElt.classList.add("emularity-splash-screen");
    if (!globalOptions.hasCustomCSS) {
      splash.splashElt.style.position = "absolute";
      splash.splashElt.style.top = "0";
      splash.splashElt.style.left = "0";
      splash.splashElt.style.right = "0";
      splash.splashElt.style.color = splash.getColor("foreground");
      splash.splashElt.style.backgroundColor = splash.getColor("background");
    }
    canvas.parentElement.appendChild(splash.splashElt);
  }

  splash.splashimg.classList.add("emularity-splash-image");
  if (!globalOptions.hasCustomCSS) {
    splash.splashimg.style.display = "block";
    splash.splashimg.style.marginLeft = "auto";
    splash.splashimg.style.marginRight = "auto";
  }
  splash.splashElt.appendChild(splash.splashimg);

  splash.titleElt = document.createElement("span");
  splash.titleElt.classList.add("emularity-splash-title");
  if (!globalOptions.hasCustomCSS) {
    splash.titleElt.style.display = "block";
    splash.titleElt.style.width = "100%";
    splash.titleElt.style.marginTop = "1em";
    splash.titleElt.style.marginBottom = "1em";
    splash.titleElt.style.textAlign = "center";
    splash.titleElt.style.font = "24px sans-serif";
  }
  splash.titleElt.textContent = " ";
  splash.splashElt.appendChild(splash.titleElt);

  let table = document.getElementById("emularity-progress-indicator");
  if (!table) {
    table = document.createElement("table");
    table.classList.add("emularity-progress-indicator");
    table.dataset.hasCustomCSS = globalOptions.hasCustomCSS;
    if (!globalOptions.hasCustomCSS) {
      table.style.width = "75%";
      table.style.color = splash.getColor("foreground");
      table.style.backgroundColor = splash.getColor("background");
      table.style.marginLeft = "auto";
      table.style.marginRight = "auto";
      table.style.borderCollapse = "separate";
      table.style.borderSpacing = "2px";
    }
    splash.splashElt.appendChild(table);
  }
  splash.table = table;
};

const keyevent = (resolve) => (e) => {
  if (e.which == 32) {
    e.preventDefault();
    resolve();
  }
};

const formatBytes = (bytes, base10) => {
  if (bytes === 0) return "0 B";
  const unit = base10 ? 1000 : 1024,
    units = base10
      ? ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"],
    exp = parseInt(Math.log(bytes) / Math.log(unit)),
    size = bytes / Math.pow(unit, exp);
  return size.toFixed(1) + " " + units[exp];
};

const resizeCanvas = (canvas, scale, resolution) => {
  if (scale && resolution) {
    // optimizeSpeed is the standardized value. different
    // browsers support different values; they will all ignore
    // values that they don't understand.
    canvas.style.imageRendering = "-moz-crisp-edges";
    canvas.style.imageRendering = "-o-crisp-edges";
    canvas.style.imageRendering = "-webkit-optimize-contrast";
    canvas.style.imageRendering = "optimize-contrast";
    canvas.style.imageRendering = "crisp-edges";
    canvas.style.imageRendering = "pixelated";
    canvas.style.imageRendering = "optimizeSpeed";

    canvas.style.width = resolution.width * scale + "px";
    canvas.style.height = resolution.height * scale + "px";
    canvas.width = resolution.width;
    canvas.height = resolution.height;
  }
};

/**
 * Prevents page navigation keys such as page up/page down from
 * moving the page while the user is playing.
 */
const blockSomeKeys = () => {
  window.onkeydown = (e) => {
    if (e.which >= 33 && e.which <= 40) {
      e.preventDefault();
      return false;
    }
    return true;
  };
};

const disableRightClickContextMenu = (element) => {
  element.addEventListener("contextmenu", function (e) {
    if (e.button == 2) {
      // Block right-click menu thru preventing default action.
      e.preventDefault();
    }
  });
};

const BFSOpenZip = (loadedData) => new BrowserFS.FileSystem.ZipFS(loadedData);

/**
 * Searches for dosbox.conf, and moves it to '/dosbox.conf' so dosbox uses it.
 */
function moveConfigToRoot(fs) {
  let dosboxConfPath = null;
  // Recursively search for dosbox.conf.
  const searchDirectory = (dirPath) => {
    fs.readdirSync(dirPath).forEach((item) => {
      if (dosboxConfPath) {
        return;
      }
      // Avoid infinite recursion by ignoring these entries, which exist at
      // the root.
      if (item === "." || item === "..") {
        return;
      }
      // Append '/' between dirPath and the item's name... unless dirPath
      // already ends in it (which always occurs if dirPath is the root, '/').
      const itemPath =
        dirPath + (dirPath[dirPath.length - 1] !== "/" ? "/" : "") + item;
      const itemStat = fs.statSync(itemPath);

      if (itemStat.isDirectory(itemStat.mode)) {
        searchDirectory(itemPath);
      } else if (item === "dosbox.conf") {
        dosboxConfPath = itemPath;
      }
    });
  };

  searchDirectory("/");

  if (dosboxConfPath !== null) {
    fs.writeFileSync(
      "/dosbox.conf",
      fs.readFileSync(dosboxConfPath, null, flag_r),
      null,
      flag_w,
      0x1a4
    );
  }
}

// This is such a hack. We're not calling the BrowserFS api
// "correctly", so we have to synthesize these flags ourselves
const flag_r = {
  isReadable: () => true,
  isWriteable: () => false,
  isTruncating: () => false,
  isAppendable: () => false,
  isSynchronous: () => false,
  isExclusive: () => false,
  pathExistsAction: () => 0,
  pathNotExistsAction: () => 1,
};

const flag_w = {
  isReadable: () => false,
  isWriteable: () => true,
  isTruncating: () => false,
  isAppendable: () => false,
  isSynchronous: () => false,
  isExclusive: () => false,
  pathExistsAction: () => 0,
  pathNotExistsAction: () => 3,
};

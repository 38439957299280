export default function extend(a, b) {
  if (a === null) return b;
  if (b === null) return a;
  let ta = typeof a,
    tb = typeof b;
  if (ta !== tb) {
    if (ta === "undefined") return b;
    if (tb === "undefined") return a;
    throw new Error("Cannot extend an " + ta + " with an " + tb);
  }
  if (Array.isArray(a)) return a.concat(b);
  if (ta === "object") {
    Object.keys(b).forEach(function (k) {
      a[k] = extend(k in a ? a[k] : undefined, b[k]);
    });
    return a;
  }
  return b;
}

import BaseLoader from "./BaseLoader";
import extend from "./extend";
import EmscriptenRunner from "./EmscriptenRunner";

export default function DosBoxLoader() {
  const config = Array.prototype.reduce.call(arguments, extend);
  config.emulator_arguments = build_dosbox_arguments(
    config.emulatorStart,
    config.files,
    config.extra_dosbox_args,
    config.images
  );
  config.runner = EmscriptenRunner;
  return config;
}
DosBoxLoader.__proto__ = BaseLoader;

DosBoxLoader.startExe = (path) => ({ emulatorStart: path });

DosBoxLoader.extraArgs = (args) => ({ extra_dosbox_args: args });

//  driver type: hdd, floppy, cdrom
DosBoxLoader.mountZip = (drive, file, drive_type) => ({
  files: [
    {
      drive: drive,
      mountpoint: "/" + drive,
      file: file,
      drive_type: drive_type || "hdd",
    },
  ],
});

// See also https://www.dosbox.com/wiki/IMGMOUNT
// drive: the drive to mount
// filepath: the location of image file, like "/c/test.iso" NOTE: this depends on the method `DosBoxLoader.mountZip` or `DosBoxLoader.mountFile`
// drive_type: floppy, iso, hdd
// file_system: iso, fat, none
DosBoxLoader.mountImage = (drive, filepath, drive_type, file_system) => ({
  images: [
    {
      drive: drive,
      filepath: filepath,
      drive_type: drive_type,
      file_system: file_system,
    },
  ],
});

const build_dosbox_arguments = (emulator_start, files, extra_args, images) => {
  let args = ["-conf", "/emulator/dosbox.conf"];

  const files_len = files.length;
  for (let i = 0; i < files_len; i++) {
    let file = files[i];
    if ("drive" in file) {
      //  See also https://www.dosbox.com/wiki/MOUNT
      if (file.drive_type === "hdd") {
        args.push("-c", `mount ${file.drive} /emulator${file.mountpoint}`);
      } else if (file.drive_type === "floppy") {
        args.push(
          "-c",
          `mount ${file.drive} /emulator${file.mountpoint} -t floppy`
        );
      } else if (file.drive_type === "cdrom") {
        args.push(
          "-c",
          `mount ${file.drive} /emulator${file.mountpoint} -t cdrom`
        );
      }
    }
  }

  if (images) {
    const images_len = images.length;
    for (let i = 0; i < images_len; i++) {
      const image = images[i];
      //See also https://www.dosbox.com/wiki/IMGMOUNT
      args.push(
        "-c",
        `imgmount ${image.drive} /emulator${image.filepath} -t ${image.drive_type} -fs ${image.file_system}`
      );
    }
  }

  if (extra_args) {
    args = args.concat(extra_args);
  }

  const path = emulator_start.split(/\\|\//); // I have LTS already
  args.push("-c", /^[a-zA-Z]:$/.test(path[0]) ? path.shift() : "c:");
  const prog = path.pop();
  if (path && path.length) args.push("-c", "cd " + path.join("/"));
  args.push("-c", prog);

  return args;
};

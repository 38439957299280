import extend from "./extend";

export default function BaseLoader() {
  return Array.prototype.reduce.call(arguments, extend);
}

BaseLoader.canvas = (id) => {
  const elem = id instanceof Element ? id : document.getElementById(id);
  return { canvas: elem };
};

BaseLoader.emulatorJS = (url) => ({ emulatorJS: url });
BaseLoader.emulatorWASM = (url) => ({ emulatorWASM: url });
BaseLoader.locateAdditionalEmulatorJS = (func) => ({
  locateAdditionalJS: func,
});
BaseLoader.fileSystemKey = (key) => ({ fileSystemKey: key });

BaseLoader.nativeResolution = (width, height) => {
  if (typeof width !== "number" || typeof height !== "number")
    throw new Error("Width and height must be numbers");
  return {
    nativeResolution: {
      width: Math.floor(width),
      height: Math.floor(height),
    },
  };
};

BaseLoader.aspectRatio = (ratio) => {
  if (typeof ratio !== "number")
    throw new Error("Aspect ratio must be a number");
  return { aspectRatio: ratio };
};

BaseLoader.sampleRate = (rate) => ({ sample_rate: rate });
BaseLoader.muted = (muted) => ({ muted: muted });
BaseLoader.mountZip =  (drive, file) => ({ files: [{ drive: drive, mountpoint: "/" + drive, file: file }] })
BaseLoader.mountFile = (filename, file) => ({ files: [{ mountpoint: filename, file: file }] })
BaseLoader.fetchFile = (title, url) => ({ title: title, url: url, optional: false })
BaseLoader.fetchOptionalFile =  (title, url) => ( { title: title, url: url, optional: true })
BaseLoader.localFile =  (title, data) => ({ title: title, data: data })